import React from 'react';
import Button from '../../atoms/button/Button';
import { Tab } from '../modal/Modal';
import useModal from '../../../hooks/useModal';
import './Insecure.css';

import scan480w from '../../../static/images/insecure/insecure-480w.png';
import scan800w from '../../../static/images/insecure/insecure-800w.png';

const Insecure: React.FC = () => {
  const { toggle, RenderModal } = useModal(Tab.SCAN);

  const handleScan = () => {
    toggle();
  };

  return (
    <div className='Insecure'>
      <RenderModal />
      <div>
        <img
          srcSet={`${scan480w} 480w,
             ${scan800w} 800w`}
          src={scan480w}
          alt='Scan'
        />
      </div>
      <div className='InsecureText'>
        <h2>Is your organization reasonably insecure?</h2>
        <p>
          We’ll inform you about easy to exploit but easy to fix vulnerabilities
          in your organization.
        </p>
        <p>
          No whiteboards or slide decks that no one is going to read again. No
          expensive tools that no one is ever going to use.
        </p>
      </div>
      <Button text={'REQUEST SECURITY RECONNAISSANCE'} onClick={handleScan} />
    </div>
  );
};

export default Insecure;
