import './TextDivider.css';

type TextDividerProps = {
  text: string;
};

const TextDivider: React.FC<TextDividerProps> = ({
  text,
}: TextDividerProps) => {
  return (
    <div className='TextDivider'>
      <div className='Divider'>
        <div />
        <div />
      </div>
      <span>{text}</span>
      <div className='Divider'>
        <div />
        <div />
      </div>
    </div>
  );
};

export default TextDivider;
