import React, { createContext, useState } from 'react';

type AppContextContextType = {
  isLight: boolean;
  toggleTheme: () => void;
};

type AppContextProps = {
  children?: React.ReactNode;
};

export const Context = createContext<AppContextContextType>({
  isLight: true,
  toggleTheme: () => {},
});

const AppContext: React.FC<AppContextProps> = ({
  children,
}: AppContextProps) => {
  const [isLight, setIsLight] = useState(true);

  const toggleTheme = () => {
    setIsLight(!isLight);
  };

  return (
    <Context.Provider value={{ isLight, toggleTheme }}>
      {children}
    </Context.Provider>
  );
};

export default AppContext;
