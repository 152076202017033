import './WeDo.css';

type WeDoProps = {
  headline: string;
  text: string;
  isLight: boolean;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconWidth: string;
  iconMargin: string;
  bottomBoarder: boolean;
};

const WeDo: React.FC<WeDoProps> = ({
  headline,
  text,
  isLight,
  Icon,
  iconWidth,
  iconMargin,
  bottomBoarder,
}: WeDoProps) => {
  const boldText = text.substr(0, text.indexOf('.'));
  const regularText = text.substr(text.indexOf('.'), text.length);
  return (
    <div className='WeDo'>
      <div className='WeDoContent'>
        {window.innerWidth < 870 ? (
          // <img src={icon} alt='Icon' />
          <div className='WeDoIcon'>
            <Icon fill={!isLight ? '#ECEEF3' : '#313131'} />
          </div>
        ) : (
          <div className='WeDoIcon'>
            <Icon
              fill={!isLight ? '#ECEEF3' : '#313131'}
              style={{
                width: iconWidth,
                margin: iconMargin,
              }}
            />
          </div>
          // <img
          //   style={{ width: iconWidth, margin: iconMargin }}
          //   src={icon}
          //   alt='Icon'
          // />
        )}

        <div className={bottomBoarder ? 'Boarder' : undefined}>
          <h3>{headline}</h3>
          <p>
            <b>{boldText}</b>
            {regularText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeDo;
