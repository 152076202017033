// https://www.npmjs.com/package/react-switch
import Switch from 'react-switch';
import './Toggle.css';

type ToggleProps = {
  state: boolean;
  onChange: () => void;
};

const Toggle: React.FC<ToggleProps> = ({ state, onChange }: ToggleProps) => {
  const handleChange = () => {
    onChange();
  };

  return (
    <div className='Toggle'>
      <Switch
        onChange={handleChange}
        checked={state}
        uncheckedIcon={false}
        checkedIcon={false}
        width={45}
        height={27}
        handleDiameter={23}
      />
    </div>
  );
};

export default Toggle;
