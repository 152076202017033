import './Clients.css';
import React, { useContext } from 'react';
import TextDivider from '../../atoms/textDivider/TextDivider';
import ClientRow from '../../molecule/clientRow/ClientRow';
import { Context } from '../appContext/AppContext';

import { ReactComponent as Tron } from '../../../static/images/customers/tron.svg';
import { ReactComponent as Ethereum } from '../../../static/images/customers/ethereum.svg';
import { ReactComponent as Status } from '../../../static/images/customers/status.svg';
import { ReactComponent as Samsung } from '../../../static/images/customers/samsung.svg';
import { ReactComponent as TronLight } from '../../../static/images/customers/tron_light.svg';
import { ReactComponent as EthereumLight } from '../../../static/images/customers/ethereum_light.svg';
import { ReactComponent as StatusLight } from '../../../static/images/customers/status_light.svg';
import { ReactComponent as SamsungLight } from '../../../static/images/customers/samsung_light.svg';
import { ReactComponent as TronMobile } from '../../../static/images/customers/tron_mobile.svg';
import { ReactComponent as EthereumMobile } from '../../../static/images/customers/ethereum_mobile.svg';
import { ReactComponent as StatusMobile } from '../../../static/images/customers/status_mobile.svg';
import { ReactComponent as SamsungMobile } from '../../../static/images/customers/samsung_mobile.svg';
import { ReactComponent as TronMobileLight } from '../../../static/images/customers/tron_mobile_light.svg';
import { ReactComponent as EthereumMobileLight } from '../../../static/images/customers/ethereum_mobile_light.svg';
import { ReactComponent as StatusMobileLight } from '../../../static/images/customers/status_mobile_light.svg';
import { ReactComponent as SamsungMobileLight } from '../../../static/images/customers/samsung_mobile_light.svg';

const clients = [
  { name: 'ethereum \n foundation', Icon: Ethereum },
  { name: 'tron', Icon: Tron },
  { name: 'status', Icon: Status },
  { name: 'samsung', Icon: Samsung },
];

const clientsLight = [
  { name: 'ethereum \n foundation', Icon: EthereumLight },
  { name: 'tron', Icon: TronLight },
  { name: 'status', Icon: StatusLight },
  { name: 'samsung', Icon: SamsungLight },
];

const clientsMobile = [
  { name: 'ethereum \n foundation', Icon: EthereumMobile },
  { name: 'tron', Icon: TronMobile },
  { name: 'status', Icon: StatusMobile },
  { name: 'samsung', Icon: SamsungMobile },
];

const clientsMobileLight = [
  { name: 'ethereum \n foundation', Icon: EthereumMobileLight },
  { name: 'tron', Icon: TronMobileLight },
  { name: 'status', Icon: StatusMobileLight },
  { name: 'samsung', Icon: SamsungMobileLight },
];

const Clients: React.FC = () => {
  const { isLight } = useContext(Context);

  return (
    <div className='Clients'>
      <TextDivider text={'OUR CLIENTS'} />
      <div className='ClientsDesktop'>
        <ClientRow clients={isLight ? clients : clientsLight} />
      </div>
      <div className='ClientsMobile'>
        <ClientRow clients={isLight ? clientsMobile : clientsMobileLight} />
      </div>
    </div>
  );
};

export default Clients;
