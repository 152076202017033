import React, { useContext } from 'react';
import './WhatWeDo.css';
import WeDo from '../../molecule/weDo/WeDo';
import { Context } from '../appContext/AppContext';

import { ReactComponent as Privacy } from '../../../static/images/icons/privacy.svg';
import { ReactComponent as Automation } from '../../../static/images/icons/automation.svg';
import { ReactComponent as Testing } from '../../../static/images/icons/testing.svg';
import { ReactComponent as Security } from '../../../static/images/icons/strategy.svg';
import { ReactComponent as Transformation } from '../../../static/images/icons/transformation.svg';
import { ReactComponent as Mechanism } from '../../../static/images/icons/mechanism.svg';

// TODO move text to file

const WhatWeDo: React.FC = () => {
  const { isLight } = useContext(Context);

  const weDo = [
    {
      headline: 'Secure Applications & Transformation',
      text: 'We research & develop secure applications. We help you define roles and processes, provide you with the tools you need to secure what you care about, even after we are gone.',
      icon: Transformation,
      iconWidth: '50px',
      iconMargin: '0 25px 0 30px',
    },
    {
      headline: 'Privacy',
      text: "Privacy breaches and violations are a huge risk for your organization's reputation. We help you keep your user data safe with in-use, at-rest and in-motion data encryption. We also identify information collected and stored unnecessarily in your data economy.",
      icon: Privacy,
      iconWidth: '40px',
      iconMargin: '0 30px 0 35px',
    },
    {
      headline: 'Security Strategy',
      text: 'Security is a complex and infinite process, so you have to invest wisely.  We model what an attacker could look like to your organization and identify assets that matter most to your organization.',
      icon: Security,
      iconWidth: '46px',
      iconMargin: '0 27px 0 32px',
    },
    {
      headline: 'Testing',
      text: 'Testing provably reduces cost and risk of failure in the long run. We write Fuzzers and Regression tests for your code to identify vulnerabilities.',
      icon: Testing,
      iconWidth: '38px',
      iconMargin: '0 31px 0 36px',
    },
    {
      headline: 'Mechanism Design',
      text: 'Fraud detection and content moderation is a huge cost sink. We love games and game theory, so we define and write sound algorithms for your applications, which reward honest users and prevent fraud.',
      icon: Mechanism,
      iconWidth: '44px',
      iconMargin: '0 28px 0 33px',
    },
    {
      headline: 'Automation',
      text: "Security testing is costly. We create and integrate scripts & tools in your CI/CD pipeline, which will improve your software security continuously, even when we're long gone.",
      icon: Automation,
      iconWidth: '50px',
      iconMargin: '0 25px 0 30px',
    },
  ];

  return (
    <div className='WhatWeDo'>
      <div className='Text'>
        <h2>What we do</h2>
      </div>
      <div className='WeDoList'>
        {weDo.map((item, idx) => {
          return (
            <WeDo
              headline={item.headline}
              text={item.text}
              Icon={item.icon}
              isLight={isLight}
              iconWidth={item.iconWidth}
              iconMargin={item.iconMargin}
              bottomBoarder={idx !== weDo.length - 1}
              key={`weDo_${idx}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WhatWeDo;
