import React, { useEffect } from 'react';
import './textCycle.css';

type TextCycleProps = {
  timeout: number;
  blendTime: number;
  messages: string[];
  active: boolean;
};

const TextCycle: React.FC<TextCycleProps> = ({
  timeout,
  messages,
  blendTime,
  active,
}: TextCycleProps) => {
  const [messageIndex, setMessageIndex] = React.useState(0);
  const [blendOut, setBlendOut] = React.useState(false);

  useEffect(() => {
    let timeoutHandler = setTimeout(() => {
      // Sets opacity to 0 blendTime before timeout
      setBlendOut(true);
      // Sets new message index and opacity to 1 after blendTime
      setTimeout(() => {
        setBlendOut(false);
        setMessageIndex((messageIndex + 1) % messages.length);
      }, blendTime);
    }, timeout - blendTime);

    !active && clearTimeout(timeoutHandler);

    return () => {
      active && clearTimeout(timeoutHandler);
    };
  });

  let msgElements = messages.map((m, idx) => {
    if (idx === messageIndex) {
      return (
        <span key={idx}
          style={{
            transition: `${blendTime}ms opacity ease`,
            opacity: blendOut ? '0' : '1',
          }}
        >
          {m}
        </span>
      );
    } else {
      return (
        <span key={idx}
          style={{
            opacity: 0,
          }}
        >
          {m}
        </span>
      );
    }
  });

  return <div className='TextCycle'>{msgElements}</div>;
};

export default TextCycle;
