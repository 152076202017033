import { useContext } from 'react';
import './Navigation.css';
import { Context } from '../appContext/AppContext';
import logoRGB from '../../../static/images/morph_logo_rgb.svg';
import logoLight from '../../../static/images/morph_logo_light_alt_2.svg';

const Navigation: React.FC = () => {
  const { isLight, toggleTheme } = useContext(Context);

  const handleClick = () => {
    toggleTheme();
  };

  return (
    <div className='Navigation'>
      <img src={isLight ? logoRGB : logoLight} alt='Logo' />
      {window.innerWidth > 560 ? (
        <div>
          {isLight ? (
            <span onClick={handleClick}>Dark</span>
          ) : (
            <span>
              <b>Dark</b>
            </span>
          )}
          <span> | </span>
          {!isLight ? (
            <span onClick={handleClick}>Light</span>
          ) : (
            <span>
              <b>Light</b>
            </span>
          )}
        </div>
      ) : undefined}
    </div>
  );
};

export default Navigation;
