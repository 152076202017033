import React from 'react';
import Toggle from '../../atoms/toggle/Toggle';
import './ThemeSwitch.css';

type ThemeSwitchProps = {
  state: boolean;
  onChange: () => void;
};

const ThemeSwitch: React.FC<ThemeSwitchProps> = ({
  state,
  onChange,
}: ThemeSwitchProps) => {
  return (
    <div className='ThemeSwitch'>
      <span>dark</span>
      <Toggle state={state} onChange={onChange} />
      <span>light</span>
    </div>
  );
};

export default ThemeSwitch;
