import React, { useState, useContext } from 'react';
import Button from '../../atoms/button/Button';
import TextInput from '../../molecule/textInput/TextInput';
import CheckBoxInput from '../../molecule/checkBoxInput/CheckBoxInput';
import './Modal.css';
import { Context } from '../appContext/AppContext';

export type InputMessageState = {
  name: string;
  message: string;
  terms: boolean;
}

export type ModalTabProps = {
  closeModal: () => void;
  showTermsConditions: (currentState: InputMessageState) => void;
  currentInputState: InputMessageState;
};

const Message: React.FC<ModalTabProps> = ({ closeModal, showTermsConditions, currentInputState }: ModalTabProps) => {
  const { isLight } = useContext(Context);

  const [name, setName] = useState(currentInputState.name);
  const [message, setMessage] = useState(currentInputState.message);
  const [terms, setTerms] = useState(currentInputState.terms);
  const [inputError, setInputError] = useState(false);
  const [sent, setSent] = useState(false);

  const textHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputError(false);
    switch (event.target.name) {
      case 'name': {
        setName(event.target.value);
        break;
      }
      case 'message': {
        setMessage(event.target.value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const checkboxHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerms(event.target.checked);
  };

  const handleSend = async () => {
    if (name && message && terms) {
      window.open(
        `mailto:contact@morphysm.com?subject=Hello from ${name}&body=${message}`
      );
      setSent(true);
    } else {
      setInputError(true);
    }
  };

  const nameError = () => {
    if (!name.length && inputError) {
      return 'Please fill out the name field.';
    }
  };

  const messageError = () => {
    if (!message.length && inputError) {
      return 'Please fill out the message field.';
    }
  };

  const checkBoxLabel = () => {
    return (
      <>
        I agree to be contacted by Morphysm and accept the
        <span onClick={() => showTermsConditions({name, message, terms})}>
          <b> Terms and Conditions</b>
        </span>
      </>
    );
  };

  const form = () => {
    return (
      <div className='ModalContact'>
        <h2>Send a message</h2>
        <p>
          Please fill out the following fields. For your security your message
          will be send using your mail client. Alternatively you can mail us
          here <a href='contact@morphysm.com'>contact@morphysm.com</a>. <br />{' '}
          We will be in contact soon.
        </p>
        <form className='ModalForm'>
          <TextInput
            label='Your name:'
            name='name'
            onChange={textHandler}
            error={nameError()}
            value={name}
          />
          <TextInput
            label='Your message:'
            name='message'
            onChange={textHandler}
            error={messageError()}
            value={message}
          />
          <CheckBoxInput
            error={!terms && inputError}
            name='terms'
            label={checkBoxLabel()}
            onChange={checkboxHandler}
            checked={terms}
          ></CheckBoxInput>
          <div className='ModalButtons'>
            <Button
              text={'Send message'}
              onClick={handleSend}
              type={!isLight ? 'dark' : undefined}
            />
            <Button text={'Cancel'} onClick={closeModal} type='light' />
          </div>
        </form>
      </div>
    );
  };

  const success = () => {
    return (
      <div className='ModalContact'>
        <h2>Message sent!</h2>
        <p>Thank you for your message, we will contact you soon.</p>
        <Button
          text={'Go Back'}
          onClick={closeModal}
          type={!isLight ? 'dark' : undefined}
        />
      </div>
    );
  };

  return sent ? success() : form();
};

export default Message;
