import React, { useContext } from 'react';
import Button from '../../atoms/button/Button';
import TextCycle from '../../atoms/textCycle/textCycle';
import { Tab } from '../modal/Modal';
import useModal from '../../../hooks/useModal';
import { Context } from '../appContext/AppContext';
import './Hero.css';

import scan480w from '../../../static/images/hero/hero-480w.png';
import scan800w from '../../../static/images/hero/hero-800w.png';

const morphismIs = [
  // 'Secure Software Development.',
  // 'Security Auditing & Analysis.',
  // 'Privacy Consulting.',
  // 'Mechanism Design.',
  'a software community.',
  'security auditing & analysis.',
  'programming the future of money.',
  'secure software development',
  'mechanism design.',
  'cryptocurrency engineering.',
  'hyperautomation.',
  'privacy consulting.',
  'distributed.',
  'feedback & data driven.',
  'internet of interaction.',
  'effective altruistism & game theory.',
  'graceful problem handling.',
];

const weIsTwo = (
  <p>
    Morphysm is a community of engineers, designers and researchers
    <br />
    contributing to security, cryptography, cryptocurrency and AI.
  </p>
);

const Hero: React.FC = () => {
  const { visible, toggle, RenderModal } = useModal(Tab.SCAN);
  const { isLight } = useContext(Context);

  const handleScan = () => {
    toggle();
  };

  return (
    <div className={`Hero${!isLight ? ' Dark' : ''}`}>
      <RenderModal />
      <div className='HeroCentered'>
        <img
          srcSet={`${scan480w} 480w,
             ${scan800w} 800w`}
          src={scan480w}
          alt='Hero'
        />
      </div>
      <h1>
        Morphysm is
        <br />{' '}
      </h1>
      <div className='HeroSecondLine'>
        <h1>
          <TextCycle
            timeout={2500}
            blendTime={600}
            messages={morphismIs}
            active={!visible}
          />
        </h1>
      </div>
      {weIsTwo}
      <Button text={'REQUEST SECURITY RECONNAISSANCE'} onClick={handleScan} />
    </div>
  );
};

export default Hero;
