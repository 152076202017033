import { Helmet } from 'react-helmet';

export type Props = {
  color: string;
};

const metaHead: React.FC<Props> = (props: Props) => {
  return (
    <Helmet>
      <meta name='theme-color' content={props.color} data-react-helmet='true' />
      <meta
        name='msapplication-navbutton-color'
        content={props.color}
        data-react-helmet='true'
      />
      <meta
        name='apple-mobile-web-app-capable'
        content='yes'
        data-react-helmet='true'
      />
      <meta
        name='apple-mobile-web-app-status-bar-style'
        content={props.color}
        data-react-helmet='true'
      />
    </Helmet>
  );
};

export default metaHead;
