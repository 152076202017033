import './CheckBoxInput.css';

type CheckBoxInputProps = {
  name?: string;
  label: React.ReactChild;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  checked: boolean;
};

const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  name,
  label,
  onChange,
  error,
  checked,
}: CheckBoxInputProps) => {
  return (
    <label className={`ModalTermsLabel${error ? ' ModalTermsLabelError' : ''}`}>
      <input
        className='ModalCheckbox'
        type='checkbox'
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <p>{label}</p>
    </label>
  );
};

export default CheckBoxInput;
