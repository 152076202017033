import React, { useContext } from 'react';
import Button from '../../atoms/button/Button';
import useModal from '../../../hooks/useModal';
import { Tab } from '../modal/Modal';
import { Context } from '../appContext/AppContext';
import './Footer.css';

import Legal from '../../molecule/legal/Legal';
import Social from '../../molecule/social/Social';
import ThemeSwitch from '../../molecule/themeSwitch/ThemeSwitch';
import logo from '../../../static/images/morph_logo_rgb.svg';
import logoLight from '../../../static/images/morph_logo_light.svg';
import { ReactComponent as Github } from '../../../static/images/icons/socialmedia/github.svg';

const Footer: React.FC = () => {
  const { isLight, toggleTheme } = useContext(Context);
  const { toggle, RenderModal } = useModal(Tab.CONTACT);

  const handleContact = () => {
    toggle();
  };

  const handleThemeToggle = () => {
    toggleTheme();
  };

  const socials = [
    // TODO create socials and connect
    {
      Logo: Github,
      link: 'https://github.com/morphysm',
    },
  ];

  if (window.innerWidth < 821) {
    return (
      <div className='Footer'>
        <RenderModal />
        <div className='Centered'>
          <img src={isLight ? logo : logoLight} alt='Logo' />
          <div className='Text'>
            <p>/ˈmɔːfɪz(ə)m/</p>
            <p>
              In mathematical category theory, a generalization or abstraction
              of the concept of a structure-preserving map.
            </p>
          </div>
          <Button
            text={'GET IN TOUCH'}
            onClick={handleContact}
            type='secondary'
          />
        </div>
        <div className='SocialAndTheme'>
          <ThemeSwitch state={isLight} onChange={handleThemeToggle} />
          <Social socials={socials} isLight={isLight} />
        </div>
        <Legal />
      </div>
    );
  } else {
    return (
      <div className='Footer'>
        <RenderModal />
        <div className='FooterRow FooterLogoMargin'>
          <img src={isLight ? logo : logoLight} alt='Logo' />
          <Button text={'GET IN TOUCH'} onClick={handleContact} />
        </div>
        <div className='FooterText'>
          <p>
            <b>/ˈmɔːfɪz(ə)m/</b>
          </p>
          <p>
            In mathematical category theory, a generalization or abstraction of
            the concept of a structure-preserving map.
          </p>
        </div>
        <div className='FooterRow'>
          <Social socials={socials} isLight={isLight} />
          <Legal />
        </div>
      </div>
    );
  }
};

export default Footer;
