import './ClientRow.css';
import Client, { ClientProps } from '../../atoms/client/Client';

type ClientRowProps = {
  clients: ClientProps[];
};

const ClientRow: React.FC<ClientRowProps> = ({ clients }: ClientRowProps) => {
  return (
    <div className='ClientRow'>
      {clients.map((client, idx) => {
        return (
          <Client name={client.name} Icon={client.Icon} key={`client_${idx}`} />
        );
      })}
    </div>
  );
};

export default ClientRow;
