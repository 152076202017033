import React, { useRef, useContext, useEffect } from 'react';
import useSize from './hooks/useSize';
import './App.css';
import Navigation from './components/organisms/navigation/Navigation';
import Hero from './components/organisms/hero/Hero';
import Clients from './components/organisms/clients/Clients';
import WhatWeDo from './components/organisms/whatWeDo/WhatWeDo';
import Insecure from './components/organisms/insecure/Insecure';
import Footer from './components/organisms/footer/Footer';
import Background from './components/organisms/background/Background';
import MetaHead from './components/atoms/metaHead/metaHead';
import { Context } from './components/organisms/appContext/AppContext';

const App: React.FC = () => {
  const target = useRef(null);
  const size = useSize(target);
  const { isLight, toggleTheme } = useContext(Context);

  // Handler method for changing theme.
  const handleThemeToggle = (event: MediaQueryList | MediaQueryListEvent) => {
    // If the preferred theme is light, but dark is active, or vice-versa.
    if ((event.matches && !isLight) || (!event.matches && isLight)) {
      toggleTheme();
    }
  };
  // Detecting preferred system theme on load. First, grab light theme query..
  const lightThemeMq = window.matchMedia('(prefers-color-scheme: light)');
  // ..then on load switch to preferred theme..
  useEffect(() => handleThemeToggle(lightThemeMq), []); // eslint-disable-line
  // ..and always change if theme changes while page is open (real-time update).
  lightThemeMq.addEventListener('change', handleThemeToggle);

  return (
    <div className={`App${!isLight ? ' Dark' : ''}`} ref={target}>
      <Background height={size.height} />
      <Navigation />
      <div id='modal-root' />
      <div className='Content'>
        <Hero />
        <Clients />
        <WhatWeDo />
        <Insecure />
      </div>
      <Footer />
      <MetaHead color={isLight ? '#FFFFFF' : '#000000'} />
    </div>
  );
};

export default App;
