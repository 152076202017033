import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Message from './Message';
import Scan from './Scan';
import TermsConditions from './TermsConditions';
import './Modal.css';

export enum Tab {
  SCAN = 0,
  CONTACT,
  TERMSCONDITIONS,
}

export type InputScanState = {
  name: string;
  email: string;
  terms: boolean;
}

export type InputMessageState = {
  name: string;
  message: string;
  terms: boolean;
}

export type ModalProps = {
  closeModal: () => void;
  tab: Tab;
};

const Modal: React.FC<ModalProps> = ({ closeModal, tab }: ModalProps) => {
  const domEl = document.getElementById('modal-root');
  const [activeTab, setActiveTab] = useState(tab);
  const [previousActiveTab, setPreviousActiveTab] = useState(tab);

  const [inputScanState, setInputScanState] = useState<InputScanState>({
    name: "",
    email: "",
    terms: false
  })

  const [inputMessageState, setInputMessageState] = useState<InputMessageState>({
    name: "",
    message: "",
    terms: false
  })

  if (!domEl) return null;

  const showScanTC = (currentState: InputScanState) => {
    setPreviousActiveTab(activeTab);
    setActiveTab(Tab.TERMSCONDITIONS);
    setInputScanState(currentState);
  }

  const showMessageTC = (currentState: InputMessageState) => {
    setPreviousActiveTab(activeTab);
    setActiveTab(Tab.TERMSCONDITIONS);
    setInputMessageState(currentState);
  }

  const closeTC = () => {
    setActiveTab(previousActiveTab);
  }

  let tabs = new Map([
    [Tab.SCAN, <Scan closeModal={closeModal} showTermsConditions={showScanTC} currentInputState={inputScanState}/>],
    [Tab.CONTACT, <Message closeModal={closeModal} showTermsConditions={showMessageTC} currentInputState={inputMessageState}/>],
    [Tab.TERMSCONDITIONS, <TermsConditions closeTermsConditions={closeTC}/>]
  ]);

  return ReactDOM.createPortal(
    <div className='ModalContainer'>
      <div className='Modal'>
        <div className='ModalTabs'>
          <button
            className={activeTab !== Tab.SCAN ? 'ModalTabInactive' : ''}
            onClick={() => setActiveTab(Tab.SCAN)}
          >
            Request A Scan
          </button>
          <button
            className={activeTab !== Tab.CONTACT ? 'ModalTabInactive' : ''}
            onClick={() => setActiveTab(Tab.CONTACT)}
          >
            Send A Message
          </button>
        </div>
        <div className='ModalContent'>{tabs.get(activeTab)}</div>
      </div>
    </div>,
    domEl
  );
};

export default Modal;
