import './TextInput.css';

type TextInputProps = {
  name?: string;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: string;
  value: string;
};

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  onChange,
  error,
  value,
}: TextInputProps) => {
  return (
    <>
      <label className='TextInputLabel'>{label}</label>
      <textarea
        name={name}
        onChange={onChange}
        className={`TextInputArea${error ? ' TextInputError' : ''}`}
        value={value}
      />
      {error && <label className='TextInputErrorLabel'>{error}</label>}
    </>
  );
};

export default TextInput;
