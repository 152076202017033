import './Legal.css';

const Legal: React.FC = () => {
  if (window.innerWidth < 481) {
    return (
      <div className='Legal'>
        <span>© morphysm 2021</span>
        <span>imprint and privacy policy</span>
      </div>
    );
  } else {
    return (
      <div className='Legal'>
        <span>
          <b>© morphysm 2021</b> - imprint and privacy policy
        </span>
      </div>
    );
  }
};

export default Legal;
