import { useState, useLayoutEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useSize = (target: React.RefObject<HTMLDivElement>) => {
  const [size, setSize] = useState(new DOMRect(0, 0));

  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => {
    setSize(entry.contentRect);
  });
  return size;
};

export default useSize;
