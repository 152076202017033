import './Background.css';

type BackgroundProps = {
  height: number;
};

const Background: React.FC<BackgroundProps> = ({ height }: BackgroundProps) => {
  return (
    <div className='Background' style={{ height: height }}>
      {window.innerWidth < 481 ? (
        <div>
          <div className='MobileCircle1' />
          <div className='MobileCircle2' />
          <div className='MobileCircle3' />
          <div className='MobileCircle4' />
          <div className='MobileCircle5' />
          <div className='MobileCircle6' />
          <div className='MobileCircle7' />
        </div>
      ) : (
        <div>
          <div className='DesktopCircle1' />
          <div className='DesktopCircle2' />
          <div className='DesktopCircle3' />
          <div className='DesktopCircle4' />
          <div className='DesktopCircle5' />
          <div className='DesktopCircle6' />
          <div className='DesktopCircle7' />
          <div className='DesktopCircle8' />
          <div className='DesktopCircle9' />
          <div className='DesktopCircle10' />
          <div className='DesktopCircle11' />
        </div>
      )}
      <div className='BackgroundNoise' />
    </div>
  );
};

export default Background;
