import './Social.css';

type SocialElement = {
  Logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  link: string;
};

type SocialProps = {
  socials: SocialElement[];
  isLight: boolean;
};

const Social: React.FC<SocialProps> = ({ socials, isLight }: SocialProps) => {
  return (
    <div className='Social'>
      {socials.map((social, idx) => {
        return (
          <a
            key={`social_${idx}`}
            href={social.link}
            target='_blank'
            rel='noreferrer'
          >
            <social.Logo fill={!isLight ? '#ECEEF3' : '#313131'} />
          </a>
        );
      })}
    </div>
  );
};

export default Social;
