import './Client.css';

export type ClientProps = {
  name: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const Client: React.FC<ClientProps> = ({ name, Icon }: ClientProps) => {
  return (
    <div className='Client'>
      <div className='ClientIcon'>
        <Icon />
      </div>
      <span>{name}</span>
    </div>
  );
};

export default Client;
