import React, { useState, useContext } from 'react';
import Button from '../../atoms/button/Button';
import TextInput from '../../molecule/textInput/TextInput';
import CheckBoxInput from '../../molecule/checkBoxInput/CheckBoxInput';
import { postScanRequest, ErrorType } from '../../../services/scan';
import './Modal.css';
import { Context } from '../appContext/AppContext';

export type InputScanState = {
  name: string;
  email: string;
  terms: boolean;
}

export type ModalTabProps = {
  closeModal: () => void;
  showTermsConditions: (currentState: InputScanState) => void;
  currentInputState: InputScanState;
};

const Scan: React.FC<ModalTabProps> = ({ closeModal, showTermsConditions, currentInputState }: ModalTabProps) => {
  const { isLight } = useContext(Context);

  const [name, setName] = useState(currentInputState.name);
  const [email, setEmail] = useState(currentInputState.email);
  const [terms, setTerms] = useState(currentInputState.terms);
  const [inputError, setInputError] = useState(false);
  const [errorType, setErrorType] = useState(0);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const textHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputError(false);
    switch (event.target.name) {
      case 'name': {
        setName(event.target.value);
        break;
      }
      case 'email': {
        setEmail(event.target.value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const checkboxHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerms(event.target.checked);
  };

  const handleSend = async () => {
    if (name && email && terms) {
      setSending(true);
      try {
        const response = await postScanRequest(name, email);
        if (response.ok) {
          setSent(true);
        } else if (response.reason === ErrorType.INCORRECT_EMAIL) {
          setInputError(true);
          setErrorType(ErrorType.INCORRECT_EMAIL);
        } else if (response.reason === ErrorType.DOMAIN_BLACKLISTED) {
          setInputError(true);
          setErrorType(ErrorType.DOMAIN_BLACKLISTED);
        }
      } catch (error) {
        //TODO handle error
      }
      setSending(false);
    } else {
      setErrorType(0);
      setInputError(true);
    }
  };

  const nameError = () => {
    if (!name.length && inputError) {
      return 'Please fill out the name field.';
    }
  };

  const emailError = () => {
    if (!email.length && inputError) {
      return 'Please fill out the email field.';
    }
    if (inputError && errorType === ErrorType.INCORRECT_EMAIL) {
      return 'Please use valid email.';
    }
    if (inputError && errorType === ErrorType.DOMAIN_BLACKLISTED) {
      return 'Please use email with a private domain';
    }
  };

  const checkBoxLabel = () => {
    return (
      <>
        I agree to be contacted by Morphysm and accept the
        <span onClick={() => showTermsConditions({name, email, terms})}>
          <b> Terms and Conditions</b>
        </span>
      </>
    );
  };

  const requestButton = () => {
    return (
      <Button
        text={'Request Scan'}
        onClick={handleSend}
        type={!isLight ? 'dark' : undefined}
        loading={sending}
      />
    );
  };

  const form = () => {
    return (
      <div className='ModalContact'>
        <h2>Request a Scan</h2>
        <p>
          <b>Beta:</b> The website scanner is still in beta, feel free to reach
          out to us with feedback. Please fill out the name field and the email
          field. To make sure you are permitted to scan the domain, we will
          contact you via email.
        </p>
        <form className='ModalForm'>
          <TextInput
            label='Your name:'
            name='name'
            onChange={textHandler}
            error={nameError()}
            value={name}
          />
          <TextInput
            label='Your email address:'
            name='email'
            onChange={textHandler}
            error={emailError()}
            value={email}
          />
          <CheckBoxInput
            error={!terms && inputError}
            name='terms'
            label={checkBoxLabel()}
            onChange={checkboxHandler}
            checked={terms}
          ></CheckBoxInput>
          <div className='ModalButtons'>
            {requestButton()}
            <Button text={'Cancel'} onClick={closeModal} type='light' />
          </div>
        </form>
      </div>
    );
  };

  const success = () => {
    return (
      <div className='ModalContact'>
        <h2>Scan requested!</h2>
        <p>
          You should receive an email with further instructions in a few
          minutes.
        </p>
        <Button
          text={'Go Back'}
          onClick={closeModal}
          type={!isLight ? 'dark' : undefined}
        />
      </div>
    );
  };

  return sent ? success() : form();
};

export default Scan;
