import React, { useState } from 'react';
import Modal, { Tab } from '../components/organisms/modal/Modal';

const useModal = (tab: Tab) => {
  const [visible, setVisible] = useState(false);

  const toggle = () => setVisible(!visible);

  const RenderModal = () => (
    <React.Fragment>
      {visible && <Modal closeModal={toggle} tab={tab} />}
    </React.Fragment>
  );

  return { visible, toggle, RenderModal };
};

export default useModal;
