import Loader from 'react-loader-spinner';
import './Button.css';

type ButtonProps = {
  text: string;
  onClick: () => void;
  type?: string;
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  type,
  loading,
}: ButtonProps) => {
  const content = () => {
    if (!loading) {
      return <span>{text}</span>;
    }
    return (
      <>
        <span>{text}</span>
        <Loader
          type='TailSpin'
          color='rgba(236, 238, 242)'
          height={30}
          width={30}
        />
      </>
    );
  };

  return (
    <div
      className={`Button${type ? ' ' + type : ''}${loading ? ' Loading' : ''}`}
    >
      <button type='button' onClick={onClick}>
        {content()}
      </button>
    </div>
  );
};

export default Button;
