export enum ErrorType {
  INCORRECT_EMAIL = 1,
  DOMAIN_BLACKLISTED,
  UNKNOWN,
}

interface PostContactRequestResult {
  ok: boolean;
  reason?: ErrorType;
}

export async function postScanRequest(
  name: string,
  email: string
): Promise<PostContactRequestResult> {
  var raw = JSON.stringify({ name, email });

  const response = await fetch(
    'https://website-backend-7bms7.ondigitalocean.app/scan',
    {
      method: 'POST',
      body: raw,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    return { ok: true };
  }

  const body = await response.json();

  // TODO improve this eg. error map
  if (body?.message === 'invalid email') {
    return { ok: false, reason: ErrorType.INCORRECT_EMAIL };
  } else if (body?.message === 'invalid email') {
    return { ok: false, reason: ErrorType.DOMAIN_BLACKLISTED };
  } else {
    return { ok: false, reason: ErrorType.UNKNOWN };
  }
}
